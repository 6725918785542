@media (min-width: 1024px) {
    .mobile-frame {
      max-width: 420px;
      height: 100vh;
      margin: 0 auto;
    }
  }
  @keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}
.slide-up {
  animation: slideUp 0.6s ease-out;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}
.custom-div ul {
  display: block;
  
  padding-left: 40px; /* Indentation for bullets */
  list-style-type: disc; /* Bullet style */          
}
.custom-div li {
  display: list-item;
  margin: 0;
  padding: 0;            
}
.custom-div p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}
.keyboard-open {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 950px;
}